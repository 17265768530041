import type {
  CoachResponsePagedData,
  HeadlineResponse,
  NilHundredResponseNil100FilterResponseRelatedPagedData,
  NilRankingResponseRelatedNilRankingResponseRelatedPagedData,
  PublicPlayerDealResponsePagedData,
} from '@on3/api';
import { MobileMPU } from 'components/Ads/MobileMPU';
import { MPU } from 'components/Ads/MPU';
import { CoverList } from 'components/Headlines/ArticleCover/CoverList';
import { FeedList } from 'components/Headlines/ArticleFeed/FeedList';
import { SEOHomeText } from 'components/Home/SEOHomeText';
import { HomeLayout } from 'components/Layouts/HomeLayout';
import { CoachSalariesWidget } from 'components/Nil/CoachSalariesWidget';
import { DealsHomeModule } from 'components/Nil/DealsHomeModule';
import { Nil100Widget } from 'components/Nil/Nil100Widget';
import { NilResources } from 'components/Nil/NilResources';
import { NilSportWidget } from 'components/Nil/NilSportWidget';
import { Connect } from 'components/Social/Connect';
import { On3Nil } from 'components/Svg/On3Nil';
import { niluData } from 'store/mocks/Connect.mock';

import { removeDuplicateArticles } from './articleHelper';
import styles from './NILHome.module.scss';

export interface NILPageProps {
  nilArticles: HeadlineResponse[] | null;
  niluArticles: HeadlineResponse[] | null;
  nil100: NilHundredResponseNil100FilterResponseRelatedPagedData | null;
  nilNews: HeadlineResponse[] | null;
  nilDeals: HeadlineResponse[] | null;
  sportsBusiness: HeadlineResponse[] | null;
  nilResources: HeadlineResponse[] | null;
  nilHSrankings: NilRankingResponseRelatedNilRankingResponseRelatedPagedData | null;
  nilDealsList: PublicPlayerDealResponsePagedData | null;
  coachSalaries: CoachResponsePagedData | null;
}

const nilViewAll = [
  {
    url: '/nil/category/college-nil-news/news/',
    text: 'View All College News',
    title: 'College NIL News',
  },
  {
    url: '/nil/category/high-school-nil-news/news/',
    text: 'View All High School News',
    title: 'High School NIL News',
  },
];

const dealsViewAll = [
  {
    url: '/nil/category/college-nil-deals/news/',
    text: 'View All College',
    title: 'College NIL Deals',
  },
  {
    url: '/nil/category/high-school-nil-deals/news/',
    text: 'View All High School',
    title: 'High School NIL Deals',
  },
];
const sportsBusinessViewAll = [
  {
    url: '/nil/category/sports-business/news/',
    text: 'View All sports Business',
    title: 'Sports Business',
  },
];

export const NILHome = ({
  nilArticles,
  nilNews,
  nil100,
  nilDeals,
  nilHSrankings,
  nilResources,
  nilDealsList,
  coachSalaries,
  sportsBusiness,
  ...props
}: NILPageProps) => {
  const { list } = nil100 || {};
  const coverArticles = nilArticles?.slice(0, 3);
  //Pulls the keys from the top 3 articles
  const coverKeys = coverArticles?.map((article) => article.key);

  const nilNewsArticles = removeDuplicateArticles({
    list: nilNews,
    keys: coverKeys,
  })?.slice(0, 5);
  const nilDealsArticles = removeDuplicateArticles({
    list: nilDeals,
    keys: coverKeys,
  })?.slice(0, 5);
  const sportsBusinessArticles = removeDuplicateArticles({
    list: sportsBusiness,
    keys: coverKeys,
  })?.slice(0, 5);

  return (
    <>
      {!!nilArticles?.length && <CoverList list={coverArticles} />}
      <SEOHomeText>
        <On3Nil className={styles.metaIcon} />
        <h1>Comprehensive NIL and Sports Business News, Data and Insights</h1>
      </SEOHomeText>
      <HomeLayout>
        <section className={styles.feed}>
          <div className={styles.inner}>
            {!nilNews?.length || (
              <FeedList
                list={nilNewsArticles}
                section="NIL News"
                viewAllLinks={nilViewAll}
              />
            )}
            <MobileMPU id={'mobile_mpu_top'} incontent />
            {!nilDeals?.length || (
              <FeedList
                list={nilDealsArticles}
                section="NIL Deals"
                viewAllLinks={dealsViewAll}
              />
            )}
            {!sportsBusinessArticles?.length || (
              <FeedList
                list={sportsBusinessArticles}
                section="Sports Business"
                viewAllLinks={sportsBusinessViewAll}
              />
            )}
          </div>
        </section>
        <aside className={styles.sidebarright}>
          <div className={styles.inner}>
            <MPU id={'mpu_top'} />
            {!!nilDealsList?.list?.length && (
              <DealsHomeModule
                list={nilDealsList?.list?.slice(0, 3)}
                viewAllLink="/nil/deals/"
              />
            )}
            {!!list?.length && <Nil100Widget list={list?.slice(0, 5)} />}
            {!!coachSalaries?.list?.length && (
              <CoachSalariesWidget list={coachSalaries?.list.slice(0, 5)} />
            )}
            <MPU id={'mpu_middle'} />
            <MobileMPU id={'mobile_mpu_middle'} incontent />
          </div>
        </aside>
        <aside className={styles.sidebarleft}>
          <div className={styles.inner}>
            <Connect list={niluData} title="NIL Connect" />
            {!!nilResources?.length && (
              <NilResources nilArticles={nilResources} />
            )}
            {!!nilHSrankings?.list?.length && (
              <NilSportWidget
                list={nilHSrankings?.list?.slice(0, 5)}
                relatedModel={nilHSrankings?.relatedModel}
              />
            )}
          </div>
        </aside>
      </HomeLayout>
    </>
  );
};
